import React from "react";
import { useState } from "react";
import "./Style.scss";

const CarousalCard = ({
  image,
  title,
  description,
  author,
  category,
  publishDate,
  readTime,
  link,
}) => {
  const [readMore, setReadMore] = useState(true);
  const newLink = link?.endsWith("/") ? link : (link + "/");

  return (
    <div className="carousalCard">
      <div className="row">
        <a href={newLink} target="_blank" rel="noopener noreferrer">
          <img src={image} className="carousal-banner" />
        </a>
      </div>
      <div className="row text">
        <div className="side-content">
          <a href={newLink} target="_blank" rel="noopener noreferrer">
            <div className="author">
              {author} in <span className="text-yellow">{category}</span>
            </div>
          </a>
          <a href={newLink} target="_blank" rel="noopener noreferrer">
            <div className="title">{title}</div>
          </a>
          <div className="description">
            {readMore ? description?.slice(0, 120) : description}
            <span onClick={() => setReadMore(false)} className="read">
              {description?.length > 120 && readMore && "... Read more"}
            </span>
          </div>
          <a href={newLink} target="_blank" rel="noopener noreferrer">
            <div className="time">
              {publishDate} . {readTime} min read
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CarousalCard;
