import React from "react";

import "./styles.scss";

const BlogCard = (props) => {
  const { thumbnail, title, about, link,author,category,publishDate,readTime } = props;
  const newLink = link?.endsWith("/") ? link : (link + "/");

  return (
    <div className="blog-card-container">
      {/* <p><span>{auther}</span> - <span>{publishDate}</span> - {readTime}min</p> */}
      <div className="blog-img-div">
        <a href={newLink} target="_blank" rel="noopener noreferrer">
          <img src={thumbnail} alt={title} />
        </a>       
      </div>

      <div className="content-div-blog">
        <a href={newLink} target="_blank" rel="noopener noreferrer">
          {
            author && category &&  <div className="author">{author} in <span className="text-yellow">{category}</span></div>
          }
        </a>
        <a href={newLink} target="_blank" rel="noopener noreferrer">
          <div className="title">
            {title}
          </div>
        </a>
        <a href={newLink} target="_blank" rel="noopener noreferrer">
          {
            publishDate && readTime &&
        <div className="time">
          {publishDate} . {readTime} min read
        </div>
          }
        </a>
        {/* <div className="about">{about}</div> */}
      </div>
      {/* </a> */}
    </div>
  );
};

export default BlogCard;
