import React from "react";
import BlogCard from "../../components/BlogCard/blogCard";
import Layout from "../../components/Layout/layout";
import "./styles.scss";
import { graphql } from "gatsby";
import BlogCarousal from "../../components/BlogCarousal/Carousal";
import { useState } from "react";
import Seo from "../../components/Seo/seo";

const Blogs = ({ data }) => {
  
  const [selected,setSelected] = useState('all');

  let filterData = data.allWpPost.nodes.filter(blog => {
    return blog.acf.category === selected;
  });

  selected === 'all' && (filterData = data.allWpPost.nodes);

  
  return (
    <>
      <Seo
        title="Latest Articles on Digital Marketing - Kraftshala Blog"
        description="Read the best articles on marketing trends, case studies, alumni stories, digital marketing courses, jobs and skills required to crack interviews. Get Reading!"
        url="https://www.kraftshala.com/blog/"
      />
 
      <div className="main-blogs-page">
        <Layout>
          <BlogCarousal data={data}/>
          <div className="blog-nav">
            <div className="blog-tab">
              <div className={`category-name `+ (selected === 'all' && `selected`)} onClick={() => setSelected('all')}>All</div>
              <div className={`category-name `+ (selected === 'Digital Marketing' && `selected`)} onClick={() => setSelected('Digital Marketing')}>Digital Marketing </div>
              <div className={`category-name `+ (selected === 'Career Guide' && `selected`)} onClick={() => setSelected('Career Guide')}>Career Guide</div>
              <div className={`category-name `+ (selected === "Content & Social Media" && `selected`)} onClick={() => setSelected('Content & Social Media')}>Content & Social Media</div>
              <div className={`category-name `+ (selected === "Marketing" && `selected`)} onClick={() => setSelected('Marketing')}>Marketing Reads</div>
            </div>
          </div>
          <ul>
            <div className="page-content inner-container">
              {[...filterData].sort((a,b) => Date.parse(b.date) - Date.parse(a.date)).map((edge) => {
                return (
                  <BlogCard
                    key={edge.id}
                    thumbnail={edge.featuredImage?.node?.mediaItemUrl}
                    title={edge.title}
                    // type={edge.type}
                    link={edge.slug}
                    author={edge.author.node.name}
                    publishDate={edge.date}
                    readTime={edge.readingTime}
                    category={edge.acf.category}
                    carousalShow={edge.acf.showInCarousal}
                  />
                );
              })}
            </div>
          </ul>
        </Layout>
      </div>
    </>
  );
};

export const query = graphql`
query {
    allWpPost {
      nodes {
          id
          date(formatString: "MMM DD, YYYY")
          title
          slug
          link
          author {
            node {
              name
            }
          }
          readingTime
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
              
            }
          }
          acf {
            showInCarousal
            category
            description
          }
        }
    }
    
  }
`;

export default Blogs;
